<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col md="10" />
          <b-col md="2">
            <b-button
              to="/portal/fixed_educations/add"
              variant="primary"
              block
            ><FeatherIcon icon="PlusIcon" />
              Ekle</b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            fixed
          >
            <template #cell(title)="data">
              <div>{{ data.item.title }}</div>
              <small class="text-muted">{{ data.item.content }}</small>
            </template>
            <template #cell(edate)="data">
              {{ moment(data.item.edate).format('LL') }}
            </template>
            <template #cell(control)="data">
              <b-button
                :to="'/portal/fixed_educations/edit/' + data.item.id"
                variant="outline-primary"
                size="sm"
              >
                Güncelle
              </b-button>
              <b-button
                class="ml-1"
                variant="outline-danger"
                size="sm"
                @click="fixedEducationDelete(data.item.id)"
              >
                Sil
              </b-button>
            </template>
          </b-table>
        </b-card-text>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    ToastificationContent,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'title',
          label: 'Başlık',
        },
        {
          key: 'edate',
          label: 'Tarih',
          thStyle: { width: '200px' },
        },
        {
          key: 'education_type',
          label: 'Eğitim Tipi',
          thStyle: { width: '200px' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '220px' },
        },
      ],
      dataQuery: {
        select: [
          'com_portal_fixed_educations.id AS id',
          'com_portal_fixed_educations.title AS title',
          'com_portal_fixed_educations.content AS content',
          'com_portal_fixed_education_types.title AS education_type',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['fixedEducations/getFixedEducations']
    },
    dataCount() {
      return this.$store.getters['fixedEducations/getFixedEducationsCount']
    },
    saveStatus() {
      return this.$store.getters['fixedEducations/getFixedEducationSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    saveStatus(val) {
      const toastData = {}
      if (val.status === true) {
        toastData.variant = 'success'
        toastData.title = 'İşlem Başarılı!'
        toastData.text = val.message
      } else {
        toastData.variant = 'danger'
        toastData.title = 'İşlem Hatası!'
        toastData.text = val.message
      }
      this.getDataList()
      this.showAlert(toastData)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    showAlert(data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: data.title,
          icon: 'BellIcon',
          text: data.text,
          variant: data.variant,
        },
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('fixedEducations/fixed_educationsList', this.dataQuery)
    },
    fixedEducationDelete(id) {
      this.$store.dispatch('fixedEducations/fixed_educationDelete', id)
    },
  },
}
</script>
